@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');

*{
  font-family: 'Mulish', sans-serif;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*/

/* nav ul li{
  display: inline;
} */

@layer components{
  header{
    /* background:url('https://www.motif.com.np/images/ielts.png');
    background-size: cover; */
    /* background- */
  }
  .nav-item{
    @apply hover:bg-green-600 text-white p-2 px-3 mx-3 rounded-sm
  }
  label{
    @apply block text-base font-medium  text-gray-700
  }
  .accordion-button:not(.collapsed) {
    @apply text-green-600
/* color:#0a8d50 */
  }
  .required-field::after {
    content: "*";
    color: red;
}
}

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
@apply w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500  transition
                ease-in-out focus:text-gray-700 focus:bg-white focus:outline-none
  }}